import AutomationSearchBuilder from "@/components/automation-search-builder";
import { TableCard } from "@/components/table-card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { HoverCard, HoverCardContent } from "@/components/ui/hover-card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Logo } from "@/components/ui/logo";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { Switch } from "@/components/ui/switch";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { getCaseStatusBadge, getVerdictBadge } from "@/lib/case";
import { camelCaseToTitleCase, cn } from "@/lib/utils";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { HoverCardTrigger } from "@radix-ui/react-hover-card";
import { useQuery } from "@tanstack/react-query";
import { ReactNode } from "@tanstack/react-router";
import { DOC_LINKS, DOCS, evaluateQuery, validateQuery } from "@wire/shared";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "sonner";

interface ManageExclusionProps {
  detectionSid?: string;
  // This helps us show a warning if we try to provide a detectionSid but one isn't present
  providedDetectionSid?: boolean;
  open: boolean;
  onClose: () => void;
  triggerText?: ReactNode;
  exclusion?: components["schemas"]["Exclusion"] | null;
  title: string;
  description: string;
  name?: string;
  query?: string;
}

async function getCases(dto: components["schemas"]["SearchCasesDto"]) {
  const response = await apiClient.POST("/cases", {
    body: dto,
  });
  return response.data ?? { totalCount: 0, data: [] };
}

export async function fetchLatestDetection(
  detectionSid: string | undefined,
  abortController: AbortController
) {
  try {
    let dsid = detectionSid;
    if (dsid == null || dsid.length == 0 || !dsid.startsWith("DTN-")) {
      const detections = await apiClient.POST("/detection", {
        body: {
          orderBy: "createdAt",
          orderDir: "desc",
        },
        signal: abortController.signal,
      });
      if (detections.error != null || detections.data.totalCount == 0) return;
      dsid = detections.data.data[0].sid;
    }
    const response = await apiClient.GET("/detection/{idOrSid}", {
      params: {
        path: {
          idOrSid: dsid,
        },
      },
      signal: abortController.signal,
    });
    if (response.error != null) return;
    return { dsid, data: response.data };
  } catch (err) {
    return;
  }
}

export default function ManageExclusion(props: ManageExclusionProps) {
  const [data, setData] = useState<
    components["schemas"]["DetectionWithEntities"] | undefined
  >();
  const [enabled, setEnabled] = useState<boolean>(
    props.exclusion?.enabled ?? false
  );
  const [query, setQuery] = useState<string | undefined>(
    props.exclusion?.queryString ?? props.query
  );
  const [caseSearchSettings, setCaseSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({});
  const [name, setName] = useState<string>(
    props.exclusion?.name ?? props.name ?? ""
  );
  const [tab, setTab] = useState<"basic" | "advanced">(
    props.exclusion == null ? "basic" : "advanced"
  );
  const [backTestResults, setBackTestResults] = useState<
    {
      detection: components["schemas"]["DetectionWithEntities"];
      excluded: boolean;
    }[]
  >([]);
  const [detectionSid, setDetectionSid] = useState<string | undefined>(
    props.detectionSid
  );
  const [backTesting, setBackTesting] = useState(false);
  const backTestSignal = useRef<AbortController | null>(null);
  const casesQuery = useQuery({
    queryKey: ["cases", props.exclusion?.id, caseSearchSettings],
    queryFn: () =>
      getCases({ ...caseSearchSettings, exclusionId: props.exclusion?.id }),
  });

  async function backTest(amount: number) {
    if (query == null) {
      toast.error("Query must not be null");
      return;
    }
    setBackTestResults([]);
    setBackTesting(true);
    let abortController = new AbortController();
    backTestSignal.current = abortController;
    const detections = await apiClient.POST("/detection", {
      body: {
        orderBy: "sid",
        orderDir: "desc",
        size: amount,
      },
    });
    for (const detection of detections.data?.data ?? []) {
      if (backTestSignal.current?.signal?.aborted) {
        setBackTesting(false);
        return;
      }
      const response = await apiClient.GET("/detection/{idOrSid}", {
        params: {
          path: {
            idOrSid: detection.id,
          },
        },
      });
      if (response.error != null) {
        toast.error(`Error loading ${detection.sid}`);
        continue;
      }
      const result = evaluateQuery(query, response.data);
      setBackTestResults((prev) => [
        ...prev,
        { detection: response.data, excluded: result },
      ]);
    }

    setBackTesting(false);
  }

  useEffect(() => {
    if (!props.open) {
      setQuery(props.query);
      setEnabled(props.exclusion?.enabled ?? false);
      setName(props.name ?? "");
    }
  }, [props.open]);

  useEffect(() => {
    if (props.query != null) {
      setQuery(props.query);
    }
  }, [props.query]);

  useEffect(() => {
    if (props.name != null) {
      setName(props.name);
    }
  }, [props.name]);

  useEffect(() => {
    if (props.exclusion != null) {
      setQuery(props.exclusion?.queryString);
      setEnabled(props.exclusion?.enabled ?? false);
      setName(props.exclusion?.name ?? "");
      setTab("advanced");
    }
  }, [props.exclusion]);

  useEffect(() => {
    if (props.detectionSid == null) return;
    setDetectionSid(props.detectionSid);
  }, [props.detectionSid]);

  useEffect(() => {
    const abortController = new AbortController();
    let aborted = false;
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const result = await fetchLatestDetection(detectionSid, abortController);
      if (aborted) return;
      if (result == null) {
        setTab("advanced");
        return;
      }
      setDetectionSid(result.dsid);
      setData(result.data);
    })();

    return () => {
      aborted = true;
      abortController.abort();
    };
  }, [detectionSid]);

  async function createExclusion() {
    if (name.length == 0) {
      toast.error("Name is required");
      return;
    }
    if (!query || query.length == 0) {
      toast.error("Query is required");
      return;
    }
    if (!validateQuery(query)) {
      toast.error("Invalid query");
      return;
    }
    const response = await apiClient.PUT("/exclusion", {
      body: {
        queryString: query,
        name,
      },
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Exclusion created");
    props.onClose();
  }

  async function updateExclusion() {
    if (props.exclusion == null) return;
    if (name.length == 0) {
      toast.error("Name is required");
      return;
    }
    if (!query || query.length == 0) {
      toast.error("Query is required");
      return;
    }
    const response = await apiClient.PATCH("/exclusion/{id}", {
      params: {
        path: {
          id: props.exclusion.id,
        },
      },
      body: {
        enabled: enabled,
        name: name,
        queryString: query,
      },
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Exclusion updated");
    props.onClose();
  }

  const jsonPreview = useMemo(() => {
    return (
      <pre className="text-xs rounded-md bg-muted p-4 overflow-auto">
        {JSON.stringify(data, null, 2)}
      </pre>
    );
  }, [data]);

  return (
    <Dialog open={props.open} onOpenChange={props.onClose}>
      {props.triggerText != null && (
        <DialogTrigger>{props.triggerText}</DialogTrigger>
      )}
      <DialogContent className="lg:max-w-[1500px] w-[80vw]">
        <DialogHeader>
          <DialogTitle className="flex flex-row gap-1">
            <span>{props.title}</span>
            <a href={DOC_LINKS.EXCLUSIONS} target="_blank" rel="noreferrer">
              <QuestionMarkCircleIcon className="w-4 h-4" />
            </a>
          </DialogTitle>
          <DialogDescription>{props.description}</DialogDescription>
        </DialogHeader>
        <div
          className={cn(
            "grid p-1 grid-cols-1 lg:grid-cols-2 overflow-x-hidden overflow-y-auto max-h-[80vh] gap-4"
          )}
        >
          <div>
            <div>
              {props.exclusion == null && (
                <Tabs value={tab} onValueChange={(v) => setTab(v as any)}>
                  <TabsList>
                    <TabsTrigger value="basic">Basic</TabsTrigger>
                    <TabsTrigger value="advanced">Advanced</TabsTrigger>
                  </TabsList>
                </Tabs>
              )}
            </div>
            <div className="mt-4">
              <Label>Sample Detection</Label>
              <Input
                onChange={(e) => setDetectionSid(e.target.value)}
                value={detectionSid}
              />
              <p className="text-xs text-muted-foreground">
                Your query will show true/false if it matches this detection
              </p>
            </div>
            <div className="mt-4">
              <Label>Name</Label>
              <Input
                placeholder="Exclusion Rule"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <p className="text-xs text-muted-foreground">
                Simple name describing the purpose of this exclusion
              </p>
            </div>
            <div className="mt-4">
              {tab == "basic" ? (
                <BasicQueryBuilder
                  query={query}
                  setQuery={setQuery}
                  detection={data}
                />
              ) : (
                <AdvancedQueryBuilder
                  query={query}
                  setQuery={setQuery}
                  detection={data}
                />
              )}
            </div>
            <div className="flex flex-col lg:flex-row mt-4 justify-between gap-2">
              <div className="flex flex-col gap-1">
                {props.exclusion != null && (
                  <>
                    <Label>Enabled</Label>
                    <Switch
                      checked={enabled}
                      onCheckedChange={(checked) => setEnabled(checked)}
                    />
                  </>
                )}
              </div>{" "}
              <div className="flex gap-2">
                <DialogClose asChild>
                  <Button variant="outline">Cancel</Button>
                </DialogClose>
                {backTesting ? (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      backTestSignal.current?.abort();
                    }}
                    variant="destructive"
                  >
                    Cancel Back Testing
                  </Button>
                ) : (
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <Button>Back Test</Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuLabel>
                        Back test your query
                      </DropdownMenuLabel>
                      <div className="text-xs px-2 py-1.5 text-muted-foreground">
                        Back testing will evaluate your query against the last N
                        detections for your team and tell you if your exclusion
                        would have matched that case.
                      </div>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem onClick={() => backTest(25)}>
                        <b>25</b>&nbsp;detections
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => backTest(50)}>
                        <b>50</b>&nbsp;detections
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => backTest(100)}>
                        <b>100</b>&nbsp;detections
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => backTest(250)}>
                        <b>250</b>&nbsp;detections
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
                <Button
                  onClick={
                    props.exclusion != null ? updateExclusion : createExclusion
                  }
                >
                  {props.exclusion != null ? "Update" : "Create"}
                </Button>
              </div>
            </div>

            {(backTesting || backTestResults.length > 0) && (
              <>
                <Separator className="my-8" />
                <div>
                  <div className="font-semibold">
                    Back Test Results{" "}
                    <span className="text-xs">
                      {backTestResults.filter((v) => v.excluded).length}/
                      {backTestResults.length} detections excluded
                    </span>
                  </div>
                  <div className="text-xs text-muted-foreground">
                    Scroll for results, select a row to preview it in the query
                    builder
                  </div>
                  <div className="max-h-[300px] mt-1 border overflow-x-hidden text-sm font-mono text-muted-foreground rounded-md p-1 flex flex-col gap-1 overflow-y-auto">
                    {backTestResults.length == 0 && (
                      <div className="text-muted-foreground">
                        No results yet...
                      </div>
                    )}
                    {backTestResults.map((v) => {
                      return (
                        <div
                          onClick={() => setDetectionSid(v.detection.sid)}
                          className="hover:bg-muted cursor-pointer transition-colors flex flex-row justify-between"
                        >
                          <div className="overflow-hidden truncate whitespace-nowrap">
                            {v.detection.sid} - {v.detection.sourceName}
                          </div>
                          <div className="ml-2 flex flex-row gap-1 flex-shrink-0">
                            {v.excluded &&
                              v.detection.wasEscalated &&
                              v.detection.verdict == "MALICIOUS" && (
                                <HoverCard openDelay={0}>
                                  <HoverCardTrigger>
                                    <Badge variant="destructive">!</Badge>
                                  </HoverCardTrigger>
                                  <HoverCardContent>
                                    Warning: This detection was originally
                                    escalated and closed as malicious. Make sure
                                    you are intending to exclude this.
                                  </HoverCardContent>
                                </HoverCard>
                              )}
                            <Badge variant={v.excluded ? "warning" : "success"}>
                              {v.excluded ? "Excluded" : "Not Excluded"}
                            </Badge>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {backTestResults.some(
                    (v) =>
                      v.excluded &&
                      v.detection.wasEscalated &&
                      v.detection.verdict == "MALICIOUS"
                  ) && (
                    <div className="text-xs text-destructive">
                      Warning: This exclusion will close detections that were
                      previously closed as known malicious. Please review the
                      results panel.
                    </div>
                  )}
                </div>
              </>
            )}

            {props.exclusion != null && (
              <>
                <Separator className="my-8" />
                <TableCard
                  query={casesQuery}
                  onUpdate={(settings) =>
                    setCaseSearchSettings({
                      ...caseSearchSettings,
                      ...settings,
                    })
                  }
                  headers={[
                    {
                      display: "ID",
                      key: "sid",
                      sortable: true,
                    },
                    {
                      display: "Category",
                      key: "subcategories",
                      format(value, row) {
                        let hoverBadge: React.ReactNode = null;
                        if (
                          row.subcategories != null &&
                          row.subcategories.length > 1
                        ) {
                          let subCats = row.subcategories.slice(1);
                          hoverBadge = (
                            <HoverCard openDelay={0} closeDelay={50}>
                              <HoverCardTrigger>
                                <Badge variant="outlineWarning">
                                  +{subCats.length}
                                </Badge>
                              </HoverCardTrigger>
                              <HoverCardContent>
                                <ul>
                                  {subCats.map((v) => (
                                    <li key={v}>{v}</li>
                                  ))}
                                </ul>
                              </HoverCardContent>
                            </HoverCard>
                          );
                        }
                        return (
                          <div className="flex flex-row gap-1">
                            <Badge variant="outlineWarning">
                              {row.subcategories?.[0]}
                            </Badge>
                            {hoverBadge}
                          </div>
                        );
                      },
                    },
                    {
                      display: "Name",
                      key: "name",
                      sortable: true,
                    },
                    {
                      display: "Status",
                      key: "status",
                      sortable: true,
                      format: (value) => getCaseStatusBadge(value),
                    },
                    {
                      display: "Verdict",
                      key: "verdict",
                      sortable: true,
                      format: (value) => getVerdictBadge(value),
                    },
                  ]}
                >
                  <CardHeader>
                    <CardTitle>Cases excluded by this exclusion</CardTitle>
                    <CardDescription>
                      Cases that have historically been excluded by this
                      exclusion
                    </CardDescription>
                  </CardHeader>
                </TableCard>
              </>
            )}
          </div>
          {tab == "advanced" && (
            <div className="overflow-auto hidden lg:block">
              {props.providedDetectionSid && props.detectionSid == null && (
                <p className="text-xs text-red-500">
                  No detections associated with this asset, showing the latest
                  detection from your account. Queries may not match, that is
                  okay.
                </p>
              )}
              {jsonPreview}
            </div>
          )}
          {tab == "basic" && (
            <div className="h-full w-full max-w-xl text-muted-foreground text-sm px-8 mx-auto flex flex-col  justify-center gap-4">
              <div className="mx-auto flex flex-col items-center gap-2">
                <Logo words className="max-h-12" />
                <h2 className="text-2xl font-semibold text-foreground">
                  Welcome to the exclusion query builder!
                </h2>
              </div>
              <p className="text-muted-foreground">
                Create powerful rules to automatically filter out recurring
                detections in your environment. When similar detections appear
                in the future, they'll be automatically closed.
              </p>
              <p className="text-muted-foreground">
                You can use the Basic or Advanced query builder. The basic query
                builder allows you to select entities and values of theirs to
                match against in the future. The advanced query builder allows
                you to use our{" "}
                <a
                  href={DOCS.EXCLUSIONS}
                  target="_blank"
                  className="text-blue-500"
                >
                  query language
                </a>{" "}
                to write exclusions that may not be possible with the basic
                builder.
              </p>
              <p>
                Curious where to get started? A common use case is to exclude
                all detections based on their category or subcategory and an
                asset. You can do that by clicking{" "}
                {data?.sid ?? "the detection"} to the left and selecting
                "Category" or "Subcategory". Then choose the asset you want to
                exclude and selecting the properties you want to exclude by.
              </p>
            </div>
          )}
        </div>
        {tab == "basic" && (
          <DialogFooter className="!justify-start">
            <div className="text-foreground w-full">
              <pre className="text-xs mt-2 w-full whitespace-pre-wrap rounded-md bg-muted p-4 overflow-auto font-mono">
                {query != null && query.length > 0 ? (
                  query
                ) : (
                  <>Select a filter to preview query</>
                )}
              </pre>
            </div>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
}

function BasicQueryBuilder(props: {
  detection?: components["schemas"]["DetectionWithEntities"];
  setQuery: (query: string) => void;
  query: string | undefined;
}) {
  const [selections, setSelections] = useState<
    { key: string; value: string }[]
  >(
    props.query
      ?.split(" AND ")
      .map((v) => {
        const [key, value] = v.split(/[@~]?=/);
        return { key, value: value?.replace(/^"(.*)"$/, "$1") };
      })
      .filter(Boolean) ?? []
  );
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    props.setQuery(
      selections.map((v) => `${v.key}="${v.value}"`).join(" AND ")
    );
  }, [selections]);

  const toggleQuery = useCallback(
    (key: string, value: string, remove = false) => {
      setSelections((prev) => {
        if (
          remove ||
          prev.find((v) => v.key == key && v.value == value) != null
        ) {
          return prev.filter((v) => v.key != key && v.value != value);
        } else if (!remove) {
          return [...prev, { key, value }];
        }
        return prev;
      });
    },
    [selections, setSelections]
  );

  const users = useMemo(() => {
    if (props.detection?.directory == null) return [];
    return props.detection.directory.map((v) => (
      <li key={v.id}>
        <BasicAsset
          title={v.displayName}
          data={v}
          description="User"
          query={props.query}
          keyPrefix="directory"
          toggleQuery={toggleQuery}
        />
      </li>
    ));
  }, [props.detection?.directory, toggleQuery, props.query]);

  const endpoints = useMemo(() => {
    if (props.detection?.endpoints == null) return [];
    return props.detection.endpoints.map((v) => (
      <li key={v.id}>
        <BasicAsset
          title={v.displayName}
          data={v}
          query={props.query}
          description="Endpoint"
          keyPrefix="endpoints"
          toggleQuery={toggleQuery}
        />
      </li>
    ));
  }, [props.detection?.endpoints, toggleQuery, props.query]);

  const files = useMemo(() => {
    if (props.detection?.files == null) return [];
    return props.detection.files.map((v) => (
      <li key={v.id}>
        <BasicAsset
          title={v.displayName}
          query={props.query}
          data={v}
          description="File"
          keyPrefix="files"
          toggleQuery={toggleQuery}
        />
      </li>
    ));
  }, [props.detection?.files, toggleQuery, props.query]);

  const locations = useMemo(() => {
    if (props.detection?.locations == null) return [];
    let items = props.detection.locations.map((v) => (
      <li key={v.id}>
        <BasicAsset
          title={v.displayName}
          query={props.query}
          data={v}
          description="Location"
          keyPrefix="locations"
          toggleQuery={toggleQuery}
        />
      </li>
    ));
    return items;
  }, [props.detection?.locations, toggleQuery, props.query]);

  const domains = useMemo(() => {
    if (props.detection?.domains == null) return [];
    let items = props.detection.domains.map((v) => (
      <li key={v.id}>
        <BasicAsset
          title={v.displayName}
          query={props.query}
          data={v}
          description="Domain"
          keyPrefix="domains"
          toggleQuery={toggleQuery}
        />
      </li>
    ));
    return items;
  }, [props.detection?.domains, toggleQuery, props.query]);

  const ips = useMemo(() => {
    if (props.detection?.ips == null) return [];
    let items = props.detection.ips.map((v) => (
      <li key={v.id}>
        <BasicAsset
          title={v.displayName}
          query={props.query}
          data={v}
          description="IP Address"
          keyPrefix="ips"
          toggleQuery={toggleQuery}
        />
      </li>
    ));
    return items;
  }, [props.detection?.ips, toggleQuery, props.query]);

  const detection = useMemo(() => {
    if (props.detection == null) return null;
    let { whatHappened, nextSteps, ...rest } = props.detection;
    return (
      <li key={props.detection.sid}>
        <BasicAsset
          title={props.detection.sid}
          query={props.query}
          data={rest}
          description="Detection"
          toggleQuery={toggleQuery}
        />
      </li>
    );
  }, [props.detection?.locations, toggleQuery, props.query]);

  return (
    <div>
      <h2 className="text-sm font-medium">Exclusion Filters</h2>
      <div className="text-xs text-muted-foreground">
        Click the assets below to expand and select properties to add to your
        exclusion
      </div>
      <ul className="flex gap-4 mt-2 flex-wrap">
        {detection}
        {users}
        {endpoints}
        {files}
        {locations}
        {ips}
        {domains}
      </ul>
    </div>
  );
}

function BasicAsset<T extends Record<string, any>>(props: {
  title: string;
  description: string;
  keyPrefix?: string;
  data: T;
  query?: string;
  toggleQuery: (key: string, value: string, remove?: boolean) => void;
}) {
  const keyPrefix = useMemo(() => {
    return props.keyPrefix != null ? props.keyPrefix + "." : "";
  }, [props.keyPrefix]);

  function toggleQuery(key: keyof T, remove = false) {
    props.toggleQuery(
      keyPrefix + (key as string),
      props.data[key] as string,
      remove
    );
  }

  const queryableProperties = useMemo(() => {
    const { displayName, ...rest } = props.data;
    return Object.entries(rest)
      .filter(([_, v]) => typeof v != "object")
      .map(([k, v]) => [k, v.toString()]);
  }, [props.data]);

  const details = useMemo(() => {
    return (
      <ul className="grid grid-cols-1 gap-2 xl:grid-cols-2">
        {queryableProperties.map(([k, v]) => {
          return (
            <li className="flex items-end flex-row gap-2" key={k + "." + v}>
              <div className="flex flex-col overflow-hidden gap-1">
                <span className="text-muted-foreground text-xs">
                  {camelCaseToTitleCase(k)}
                </span>
                <div className="flex text-sm items-center gap-1">
                  <Checkbox
                    checked={new RegExp(
                      `( |^)${keyPrefix}${k}[@~]?="?${escapeRegExp(v)}"?`
                    ).test(props.query ?? "")}
                    id={k}
                    onClick={(e) => e.stopPropagation()}
                    onCheckedChange={() => toggleQuery(k)}
                  />
                  <Label
                    className="font-normal text-ellipsis truncate cursor-pointer"
                    htmlFor={k}
                  >
                    {v}
                  </Label>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  }, [queryableProperties, props.query]);

  return (
    <div className="px-2 pb-1 rounded-md border shadow-sm hover:bg-muted">
      <Popover modal>
        <PopoverTrigger>
          <div className="flex flex-col cursor-pointer  items-start">
            <div className="text-xs text-muted-foreground">
              {props.description}
            </div>
            <div className="flex text-sm flex-row items-center gap-2">
              {props.title}
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent
          side="right"
          className="w-full max-w-[800px] PopoverContent h-full"
        >
          <h3 className="font-medium text-sm">{props.title}</h3>
          <Separator className="my-2" />
          <div>{details}</div>
          <Separator className="my-2" />
          <div className="text-xs text-muted-foreground">
            Looking to exclude this specific entity? Select the `id` field.
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
function escapeRegExp(string?: string) {
  if (!string) return;
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

function AdvancedQueryBuilder(props: {
  detection?: components["schemas"]["DetectionWithEntities"];
  setQuery: (query: string) => void;
  query: string | undefined;
}) {
  const [validQuery, setValidQuery] = useState<boolean>(false);
  const [queryMatch, setQueryMatch] = useState<boolean>(false);

  useEffect(() => {
    if (props.query == null) return;
    setValidQuery(validateQuery(props.query));
  }, [props.query]);

  return (
    <div>
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-2">
        <Label>Query</Label>
        {props.query != null && props.query.length > 0 && (
          <div className={cn("mb-2 flex transition-opacity flex-row gap-2")}>
            <Badge variant={validQuery ? "success" : "destructive"}>
              {validQuery ? "Valid Query" : "Invalid Query"}
            </Badge>
            <Badge variant={queryMatch ? "success" : "warning"}>
              {queryMatch ? "Query Matches" : "Query Does Not Match"}
            </Badge>
          </div>
        )}
        {props.query == null ||
          (props.query.length == 0 && (
            <p className={cn("text-xs my-1.5 text-muted-foreground")}>
              Type a query to begin
            </p>
          ))}
      </div>

      <AutomationSearchBuilder
        onResultChange={setQueryMatch}
        onQueryChange={props.setQuery}
        defaultQuery={props.query}
        data={props.detection}
      />
    </div>
  );
}
