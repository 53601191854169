import AddIntegration, {
  IntegrationLogo,
} from "@/components/settings/add-integration";
import DeleteIntegration from "@/components/settings/integrations/delete-integration";
import ToggleIntegration from "@/components/settings/integrations/toggle-integration";
import { TableCard } from "@/components/table-card";
import { Badge, BadgeProps } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

import { camelCaseToTitleCase, cn, hyphenCaseToTitleCase } from "@/lib/utils";

import IntegrationIdentity from "@/components/integration/integration-identity";
import { apiClient, formatSearchQuery } from "@/lib/api";
import { components } from "@/lib/api.types";
import { getTimezone } from "@/lib/time";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, SearchSchemaInput } from "@tanstack/react-router";
import { IntegrationLogType, IntegrationLogTypeConfig } from "@wire/shared";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useMemo, useState } from "react";

async function getIntegration(id: string) {
  const response = await apiClient.GET("/integration/{id}", {
    params: { path: { id } },
  });
  if (response.error != null) {
    throw new Error("Error getting register information");
  }
  let integration = response.data;

  return { integration };
}

function getLogTypeBadgeVariant(
  type: IntegrationLogType
): BadgeProps["variant"] {
  switch (type) {
    case IntegrationLogType.ERROR:
      return "destructive";
    case IntegrationLogType.WARNING:
      return "warning";
    default:
      return "success";
  }
}

// Add new query keys and functions from logs.tsx
export const INTEGRATION_LOG_QUERY_KEY = "INTEGRATION_LOG";
export const INTEGRATION_LOG_DAILY_QUERY_KEY = "INTEGRATION_LOG_DAILY";

export const getIntegrationLogOptions = (
  dto: components["schemas"]["IntegrationLogSearchDto"] = {}
) => {
  return queryOptions({
    queryKey: [INTEGRATION_LOG_QUERY_KEY, dto],
    queryFn: () => getIntegrationLog(dto),
    placeholderData: keepPreviousData,
  });
};

export const getIntegrationLogDailyOptions = (
  dto: components["schemas"]["IntegrationLogSearchDto"] = {}
) => {
  return queryOptions({
    queryKey: [INTEGRATION_LOG_DAILY_QUERY_KEY, dto],
    queryFn: () => getIntegrationLogDaily(dto),
    placeholderData: keepPreviousData,
  });
};

async function getIntegrationLog(
  dto: components["schemas"]["IntegrationLogSearchDto"]
) {
  const response = await apiClient.POST("/integration/logs", {
    body: {
      ...dto,
      search: formatSearchQuery(dto.search),
    },
  });
  if (response.error != null) {
    throw new Error("Error getting integration logs");
  }
  return response.data;
}

async function getIntegrationLogDaily(
  dto: components["schemas"]["IntegrationLogSearchDailyDto"]
) {
  const response = await apiClient.POST("/integration/logs/daily", {
    body: { ...dto, timezone: getTimezone() },
  });
  if (response.error != null) {
    throw new Error("Error getting daily integration logs");
  }
  return response.data;
}

export const INTEGRATION_QUERY_KEY = "INTEGRATION";
export const getIntegrationOptions = (id: string) =>
  queryOptions({
    queryKey: [INTEGRATION_QUERY_KEY, id],
    queryFn: () => getIntegration(id),
  });

export const Route = createFileRoute(
  "/_application/settings/integrations/$integrationId"
)({
  validateSearch: (
    search: {
      metadataId?: string;
    } & SearchSchemaInput
  ) => {
    return {
      metadataId: search.metadataId,
    };
  },
  component: Integration,
  loader: async ({ params, context }) => {
    const [integration] = await Promise.all([
      context.queryClient.ensureQueryData(
        getIntegrationOptions(params.integrationId)
      ),
      context.queryClient.ensureQueryData(
        getIntegrationLogOptions({ integrationId: params.integrationId })
      ),
      context.queryClient.ensureQueryData(
        getIntegrationLogDailyOptions({ integrationId: params.integrationId })
      ),
    ]);
  },
});

function Integration() {
  const { integrationId } = Route.useParams();
  const { metadataId } = Route.useSearch();
  const queryClient = useQueryClient();
  const [isLogsOpen, setIsLogsOpen] = useState(false);
  const [logSearchSettings, setLogSearchSettings] = useState<
    components["schemas"]["IntegrationLogSearchDto"]
  >({
    integrationId,
  });

  const {
    data: { integration },
  } = useSuspenseQuery(getIntegrationOptions(integrationId));

  const dailyLogSearchSettings = useMemo(
    () => ({
      integrationMetadataId: logSearchSettings.integrationMetadataId,
      integrationId: logSearchSettings.integrationId,
    }),
    [logSearchSettings]
  );

  const dailyLogQuery = useQuery(
    getIntegrationLogDailyOptions(dailyLogSearchSettings)
  );
  const logQuery = useQuery(getIntegrationLogOptions(logSearchSettings));
  const formattedDailyLogs = useMemo(() => {
    if (dailyLogQuery.isLoading) return [];
    const dayGroups: {
      [day: string]: {
        [type: string]: {
          count: number;
          datetime: string;
        };
      };
    } = dailyLogQuery.data?.reduce((acc: any, v) => {
      const day = moment(v.day).format("MMM DD");
      if (acc[day] == null) {
        acc[day] = {};
      }
      Object.values(IntegrationLogType).forEach((type) => {
        if (v.group == type.toString()) {
          acc[day][type] = {
            count: v.count,
            datetime: v.day,
          };
        }
      });
      acc[day].day = { datetime: day };
      return acc;
    }, {});

    // Convert to array and find first non-zero day
    const dayArray = Object.entries(dayGroups);
    const firstNonZeroIndex = dayArray.findIndex(([_, values]) => {
      const total = Object.entries(values).reduce((sum, [key, value]) => {
        if (key === "day") return sum;
        return sum + value.count;
      }, 0);
      return total >= 0;
    });

    // Return array starting from first non-zero day
    return dayArray.slice(firstNonZeroIndex).map(([_, value]) => value);
  }, [dailyLogQuery.data]);

  const reversedDailyLogs = useMemo(() => {
    return [...formattedDailyLogs].reverse();
  }, [formattedDailyLogs]);

  const chartConfig = useMemo(
    () => ({
      [IntegrationLogType.ERROR]: {
        label: "Error",
        color: "hsl(var(--chart-destructive))",
      },
      [IntegrationLogType.WARNING]: {
        label: "Warning",
        color: "hsl(var(--chart-warning))",
      },
      [IntegrationLogType.INFO]: {
        label: "Info",
        color: "hsl(var(--chart-success))",
      },
    }),
    []
  );

  return (
    <div className="h-full flex flex-col divide-y">
      <CardHeader className="bg-background ">
        <div className="flex flex-col gap-4 lg:flex-row lg:items-center items-start lg:justify-between">
          <div>
            <CardTitle className="flex gap-1 text-xl items-center">
              <IntegrationLogo
                config={integration.config}
                className="max-h-6 max-w-6"
              />
              {integration.config.name}
              {integration.config.beta && <Badge className="ml-1">Beta</Badge>}

              {integration.permissionsUpdateAvailable && (
                <div className="ml-2">
                  <AddIntegration
                    update
                    integrationToUpdate={integration}
                    integrationSlug={"microsoft"}
                  />
                </div>
              )}
            </CardTitle>
            <CardDescription>
              <IntegrationIdentity
                identityFields={integration.identityFields}
                integrationPlatform={integration.platform}
              />
            </CardDescription>
          </div>
          <div className="flex flex-wrap gap-2">
            <ToggleIntegration
              integration={integration}
              onComplete={() => {
                void queryClient.invalidateQueries({
                  queryKey: [INTEGRATION_QUERY_KEY, integration.id],
                });
              }}
            />
            <DeleteIntegration integration={integration} />
          </div>
        </div>
      </CardHeader>
      <Card className="rounded-none border-0 shadow-none">
        <CardHeader>
          <CardTitle>Integration Events</CardTitle>
          <div>
            <div className="flex justify-between self-start flex-row-reverse overflow-y-auto overflow-x-auto grid-flow-col gap-1 h-auto rounded-md bg-muted/50 p-2">
              {reversedDailyLogs.map((dayData) => (
                <div key={dayData.day.datetime} className="flex flex-col">
                  <div className="text-[.6rem] text-center w-6 text-muted-foreground mb-1">
                    {dayData.day.datetime}
                  </div>
                  <div className="flex-1 flex flex-col w-7 gap-1">
                    {Object.entries(chartConfig).map(([type, config]) => (
                      <div
                        onClick={() => {
                          if (
                            logSearchSettings.day == dayData[type]?.datetime &&
                            logSearchSettings.type == type
                          ) {
                            setLogSearchSettings({
                              ...logSearchSettings,
                              day: undefined,
                              type: undefined,
                            });
                          } else {
                            setLogSearchSettings({
                              ...logSearchSettings,
                              day: dayData[type].datetime,
                              type: type as IntegrationLogType,
                            });
                            setIsLogsOpen(true);
                          }
                        }}
                        key={type}
                        className={cn(
                          "flex-1 rounded cursor-pointer overflow-hidden flex items-center justify-center text-xs",
                          {
                            "ring-1 ring-primary":
                              logSearchSettings.day ==
                                dayData[type]?.datetime &&
                              logSearchSettings.type == type,
                          }
                        )}
                        style={{
                          backgroundColor: dayData[type]?.count
                            ? config.color
                            : "transparent",
                          color: dayData[type]?.count ? "white" : "inherit",
                        }}
                      >
                        {dayData[type] != null && dayData[type].count > 0
                          ? numeral(dayData[type]?.count).format("0,0a")
                          : "-"}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </CardHeader>
        <CardContent className="space-y-4">
          <TableCard
            embedded
            query={logQuery}
            className="min-h-0"
            contentClassName="px-0"
            footerClassName="px-0"
            onUpdate={(e) => {
              setLogSearchSettings({
                ...logSearchSettings,
                ...e,
              });
            }}
            searchable
            headers={[
              {
                key: "context",
                display: "Context",
                format: (v: string) => {
                  if (v == null) return "-";
                  if (v.includes(" ")) {
                    return v;
                  }
                  return v.includes("-")
                    ? hyphenCaseToTitleCase(v)
                    : camelCaseToTitleCase(v);
                },
              },
              {
                key: "message",
                display: "Log Message",
                format: (v) => v,
              },
              {
                key: "createdAt",
                display: "Event Time",
                dateTime: true,
              },
              {
                key: "type",
                display: "Log Level",
                format: (v) => (
                  <Badge
                    variant={getLogTypeBadgeVariant(v as IntegrationLogType)}
                  >
                    {IntegrationLogTypeConfig[v as IntegrationLogType]?.display}
                  </Badge>
                ),
              },
            ]}
          />
        </CardContent>
      </Card>
    </div>
  );
}

function UpdatePermissionsButton(props: {
  integration: components["schemas"]["IntegrationV2"];
}) {
  const [url, setUrl] = useState<string>();
  useEffect(() => {
    async function loadUrl() {
      let response = await apiClient.POST("/integration/oauth/url", {
        params: {
          query: {
            integration: props.integration.platform,
          },
        },
        body: {},
      });
      setUrl(response.data?.url);
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadUrl();
  }, []);
  return (
    <a href={url} target="_blank">
      <Button variant="warning">Click Here to Update Permissions</Button>
    </a>
  );
}
