import { AppLayout } from "@/components/app-layout";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Collapsible, CollapsibleContent } from "@/components/ui/collapsible";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { apiClient } from "@/lib/api";
import { localDateTime } from "@/lib/time";
import { cn, dashCaseToTitleCase } from "@/lib/utils";
import { getQueueStatusVariant } from "@/routes/_application/admin/queues";
import { CollapsibleTrigger } from "@radix-ui/react-collapsible";
import {
  queryOptions,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useMemo, useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_application/admin/$queueName_/$jobId")({
  component: AdminQueueJob,
  loader: async ({ params, context }) => {
    await context.queryClient.ensureQueryData(
      getOptions(params.queueName, params.jobId)
    );
  },
});

async function getData(name: string, id: string) {
  const [job] = await Promise.all([
    apiClient.GET("/admin/queue/{name}/{id}", {
      params: { path: { id, name } },
    }),
  ]);
  if (job.error != null) {
    throw new Error("Error getting job information");
  }
  return {
    job: job.data,
  };
}

export const ADMIN_QUEUE_QUERY_KEY = "admin-queue";
const getOptions = (name: string, id: string) =>
  queryOptions({
    queryKey: [ADMIN_QUEUE_QUERY_KEY, name, id],
    queryFn: () => getData(name, id),
    refetchInterval: 2500,
  });

function AdminQueueJob() {
  const { queueName, jobId } = Route.useParams();
  const {
    data: { job },
  } = useSuspenseQuery(getOptions(queueName, jobId));
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const formattedQueueName = useMemo(() => {
    return dashCaseToTitleCase(queueName);
  }, [queueName]);

  const displayProperties = useMemo(() => {
    const { logs, stackTrace, ...rest } = job;

    return rest;
  }, [job]);

  async function retry() {
    const response = await apiClient.POST("/admin/queue/{name}/{id}/retry", {
      params: {
        path: {
          name: queueName,
          id: jobId,
        },
      },
    });
    if (response.error != null) {
      toast.error("Error retrying job");
    } else {
      toast.warning("Retrying job");
    }
    await queryClient.invalidateQueries({
      queryKey: [ADMIN_QUEUE_QUERY_KEY, queueName, jobId],
    });
  }

  async function deleteRepeatableJob() {
    const response = await apiClient.DELETE(
      "/admin/queue/{name}/{id}/repeatable",
      {
        params: {
          path: {
            name: queueName,
            id: jobId,
          },
        },
      }
    );
    if (response.error != null) {
      toast.error("Error deleting job");
      return;
    } else {
      toast.warning("Deleted job");
    }
    await navigate({
      to: "/admin/$queueName",
      params: { queueName: queueName },
    });
  }

  async function deleteJob() {
    const response = await apiClient.DELETE("/admin/queue/{name}/{id}", {
      params: {
        path: {
          name: queueName,
          id: jobId,
        },
      },
    });
    if (response.error != null) {
      toast.error("Error deleting job");
      return;
    } else {
      toast.warning("Deleted job");
    }
    await navigate({
      to: "/admin/$queueName",
      params: { queueName: queueName },
    });
  }

  return (
    <AppLayout>
      <Card>
        <CardHeader className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:justify-between">
          <div>
            <CardTitle>{formattedQueueName}</CardTitle>
            <CardDescription className="flex flex-col space-y-2 items-start">
              <div>Job {jobId}</div>
              <Badge variant={getQueueStatusVariant(job.state as any)}>
                {job.state}
              </Badge>
            </CardDescription>
          </div>
          <div className="flex gap-2 flex-wrap">
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="outline">View Details</Button>
              </DialogTrigger>
              <DialogContent className="max-w-[80vw] flex flex-col max-h-[80vh] overflow-auto">
                <DialogHeader>
                  <DialogTitle>Job Details</DialogTitle>
                </DialogHeader>
                <pre className="overflow-auto">
                  {JSON.stringify(displayProperties, null, 2)}
                </pre>
              </DialogContent>
            </Dialog>
            {job.repeatJobKey && (
              <Dialog>
                <DialogTrigger asChild>
                  <Button variant="outlineDestructive">
                    Delete Repeatable
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogDescription>
                      This will remove all past and future runs of this job
                    </DialogDescription>
                  </DialogHeader>
                  <DialogFooter className="justify-end flex gap-2">
                    <DialogClose asChild>
                      <Button variant="outline">No</Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button
                        variant="destructive"
                        onClick={deleteRepeatableJob}
                      >
                        Yes
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            )}
            {(!job.repeatJobKey || job.state != "delayed") &&
              job.state != "active" && (
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant="outlineDestructive">Delete</Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Are you sure?</DialogTitle>
                      <DialogDescription>
                        This will remove all data related to this job
                        permanently
                      </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className="justify-end flex gap-2">
                      <DialogClose asChild>
                        <Button variant="outline">No</Button>
                      </DialogClose>
                      <DialogClose asChild>
                        <Button variant="destructive" onClick={deleteJob}>
                          Yes
                        </Button>
                      </DialogClose>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              )}

            {(job.state == "failed" || job.state == "completed") && (
              <Dialog>
                <DialogTrigger asChild>
                  <Button>Retry</Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogDescription>
                      This will rerun this job and potentially trigger client
                      interactions!
                    </DialogDescription>
                  </DialogHeader>
                  <DialogFooter className="justify-end flex gap-2">
                    <DialogClose asChild>
                      <Button variant="outline">No</Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button onClick={retry}>Yes</Button>
                    </DialogClose>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            )}
          </div>
        </CardHeader>
        <CardContent className="flex space-y-4 flex-col">
          <div className="flex flex-wrap gap-4">
            <ValueSection title="ID" value={job.id} />
            <ValueSection title="Name" value={job.name} />
            {job.processedOn != null && (
              <ValueSection
                title="Processed On"
                value={localDateTime(job.processedOn)}
              />
            )}
            {job.finishedOn != null && (
              <ValueSection
                title="Finished On"
                value={localDateTime(job.finishedOn)}
              />
            )}
            <ValueSection title="Processed By" value={job.processedBy} />
            <ValueSection title="Repeat Key" value={job.repeatJobKey} />
          </div>
          <DataSection title="Input" data={JSON.stringify(job.data, null, 2)} />
          <DataSection
            title="Children Values"
            data={JSON.stringify(job.childrenValues, null, 2)}
          />
          <DataSection
            title="Return Value"
            data={JSON.stringify(job.returnValue, null, 2)}
          />
          <DataSection title="Logs" data={job.logs.join("\n")} />
          <DataSection title="Error" data={job.stackTrace.join("\n")} />
        </CardContent>
      </Card>
    </AppLayout>
  );
}

function ValueSection(props: { title: string; value: any }) {
  if (props.value == null) return;
  return (
    <div className="flex flex-col space-y-2">
      <Label>{props.title}</Label>
      <div>{props.value}</div>
    </div>
  );
}

function DataSection(props: { title: string; data: string }) {
  if (!props.data) return;
  const [open, setOpen] = useState(false);
  let sliced = useMemo(() => {
    return props.data.split("\n");
  }, [props.data]);
  const expandable = useMemo(() => {
    return sliced.length > 3;
  }, [sliced]);
  return (
    <div className="w-full">
      <h2 className="text-xl font-bold">{props.title}</h2>
      <Collapsible className="mt-2" onOpenChange={setOpen} open={open}>
        <CollapsibleTrigger disabled={!expandable} asChild>
          {!open && (
            <pre
              className={cn("bg-muted p-4 rounded-md", {
                "cursor-pointer hover:shadow-md": expandable,
              })}
            >
              {sliced.slice(0, 3).join("\n")}
              {expandable && (
                <>
                  <br />
                  <span className="text-sm italic">Click to see more</span>
                </>
              )}
            </pre>
          )}
        </CollapsibleTrigger>
        <CollapsibleContent
          className="cursor-pointer"
          onClick={() => setOpen(false)}
        >
          <pre className="bg-muted overflow-auto p-4 rounded-md">
            {props.data}
          </pre>
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
}
