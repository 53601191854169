"use client";

import { add } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import * as React from "react";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { localDateTime } from "@/lib/time";
import { cn } from "@/lib/utils";
import { DateAfter, DateBefore, Matcher } from "react-day-picker";
import { TimePicker } from "./time-picker";

export function DateTimePicker(props: {
  date?: string;
  notBefore?: string;
  notAfter?: string;
  setDate: (d: string) => void;
}) {
  const [date, setDate] = React.useState<Date | undefined>(
    props.date != null ? new Date(props.date) : undefined
  );

  /**
   * carry over the current time when a user clicks a new day
   * instead of resetting to 00:00
   */
  const handleSelect = (newDay: Date | undefined) => {
    if (newDay == null) return;
    if (date == null) {
      setDate(newDay);
      return;
    }

    const diff = newDay.getTime() - date.getTime();
    const diffInDays = diff / (1000 * 60 * 60 * 24);
    const newDateFull = add(date, { days: Math.ceil(diffInDays) });
    updateDate(newDateFull);
  };

  const updateDate = (newDate: Date | string | undefined) => {
    if (newDate == null) {
      newDate = new Date();
    } else if (typeof newDate === "string") {
      newDate = new Date(newDate);
    }
    setDate(newDate);
    props.setDate(newDate.toISOString());
  };

  const disabledDays = React.useMemo(() => {
    let out: Partial<DateBefore & DateAfter>[] = [];
    if (props.notBefore != null) {
      out.push({ before: new Date(props.notBefore) });
    }
    if (props.notAfter != null) {
      out.push({ after: new Date(props.notAfter) });
    }
    return out;
  }, [props.notBefore, props.notAfter]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-start text-left font-normal",
            date == null && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date != null ? (
            localDateTime(date.toISOString())
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          disabled={disabledDays as Matcher[]}
          onSelect={(d) => handleSelect(d)}
          initialFocus
        />
        <div className="p-3 border-t border-border">
          <TimePicker
            disabled={disabledDays}
            setDate={updateDate}
            date={date}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}
