import { TableCard } from "@/components/table-card";
import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { apiClient, formatSearchQuery } from "@/lib/api";
import { components } from "@/lib/api.types";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createFileRoute,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import { useState } from "react";

async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export const Route = createFileRoute("/_application/assets/user-agents/")({
  component: UserAgents,
  loaderDeps: ({ search }) => {
    return {
      search,
    };
  },
  loader: ({ context, deps }) => {
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    context.queryClient.ensureQueryData(getOptions(deps.search));
    context.title = "User Agents";
  },
});

function getOptions(
  settings: components["schemas"]["UserAgentSearchDto"] = {}
) {
  return queryOptions({
    queryKey: [USER_AGENTS_QUERY_KEY, settings],
    queryFn: () => getUserAgents(settings),
    placeholderData: keepPreviousData,
  });
}

async function getUserAgents(
  searchSettings: components["schemas"]["UserAgentSearchDto"] = {}
) {
  let search = formatSearchQuery(searchSettings.search);
  const response = await apiClient.POST("/user-agent", {
    body: { ...searchSettings, search },
  });

  if (response.error != null) {
    throw new Error("Error getting user agents");
  }

  return response.data;
}

const USER_AGENTS_QUERY_KEY = "settings-user-agents";

export default function UserAgents() {
  const queryClient = useQueryClient();
  const urlSearch = useSearch({ from: "/_application/assets/user-agents/" });
  const [searchSettings, setSearchSettings] =
    useState<components["schemas"]["UserAgentSearchDto"]>(urlSearch);
  const navigate = useNavigate();
  function updateSearchSettings(
    settings: components["schemas"]["UserAgentSearchDto"]
  ) {
    setSearchSettings({
      ...searchSettings,
      ...settings,
    });
  }
  const query = useQuery(getOptions(searchSettings));

  return (
    <TableCard
      embedded
      onUpdate={updateSearchSettings}
      query={query}
      storeStateInUrl
      searchable
      onClickNavigate={(row) => ({
        to: "/assets/user-agents/$userAgentId",
        params: { userAgentId: row.id },
      })}
      compact
      headers={[
        {
          display: "User Agent",
          key: "userAgent",
          sortable: true,
          truncate: 60,
        },
        {
          display: "Browser",
          key: "browserName",
          sortable: true,
        },
        {
          display: "Browser Version",
          key: "browserVersion",
          sortable: true,
        },
        {
          display: "OS",
          key: "osName",
          sortable: true,
        },
        {
          display: "OS Version",
          key: "osVersion",
          sortable: true,
        },
        {
          display: "Device Model",
          key: "deviceModel",
          sortable: true,
        },
        {
          display: "Device Vendor",
          key: "deviceVendor",
          sortable: true,
        },
        {
          display: "CPU Architecture",
          key: "cpuArchitecture",
          sortable: true,
        },
        {
          display: "Engine",
          key: "engineName",
          sortable: true,
        },
        {
          display: "Engine Version",
          key: "engineVersion",
          sortable: true,
        },
        {
          display: "Created At",
          key: "createdAt",
          sortable: true,
        },
      ]}
    >
      <CardHeader>
        <div className="flex  gap-4 items-start lg:items-center flex-col lg:flex-row justify-between">
          <div className="flex flex-col gap-2">
            <CardTitle>User Agents</CardTitle>
            <CardDescription>
              User agents that have been extracted from detections in your
              environment.
            </CardDescription>
          </div>
        </div>
      </CardHeader>
    </TableCard>
  );
}
